<template>
  <div>
    <app-modal v-show="isModalVisible" :modal="modal" @submit-modal="submitCancelNote" />
    <table-filter
      @filterTable="filterTable"
      :items="yearOptions"
      :label="$t('models.leaveRequest.attributes.year')">
    </table-filter>
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="visibleHeaders"
        :items="leaves.items"
        :options.sync="options"
        :server-items-length="leaves.count"
        :loading="loading"
        :expanded.sync="expanded"
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.leaveRequest.entity', 2) })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.leaveRequest.entity', 2) })"
        @update:options="updateOptions"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        show-expand>
        <template v-slot:[`item.userId`]="{ item }">
          <v-list-item>
            <v-list-item-avatar size="45">
              <app-avatar :url="getFileUrl(item.user.avatar)" :alternative="item.user.initials" class="no-padding" />
            </v-list-item-avatar>
            <v-list-item-content v-text="item.user.name" />
          </v-list-item>
        </template>
        <template v-slot:[`item.requestedDates`]="{ item }">
          {{ item.startDateWithTimezone }}
          <template v-if="item.startDateWithTimezone !== item.endDateWithTimezone && item.unit !== leaveUnits.HOURS">
           - {{ item.endDateWithTimezone }}
          </template>
          <template v-if="item.unit !== leaveUnits.FULL_DAY">
            <v-chip small>{{ $t(`models.leaveRequest.attributes.units.${item.unit}`) }}</v-chip>
          </template>
        </template>
        <template v-slot:[`item.duration`]="{ item }">
          {{ item.duration.convention }} ({{ item.duration.hours }}{{ $t('models.leaveRequest.attributes.hours') }})
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip small v-show="tableName !== leaveTables.MANAGER_PENDING_REQUESTS">
            <v-icon left small :color="statuses[item.status].color">{{ statuses[item.status].icon }}</v-icon>
            {{ $t(`models.leaveRequest.attributes.statuses.${item.status}`) }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small
            @click.stop="manageDates(leaveStatus.APPROVED, item, leaves.items.indexOf(item))"
            v-if="canManage(item.status, item.user.id) && noActionTakenOnAnyDateByThisManager(item.dates)">
            <v-icon>mdi-thumb-up-outline</v-icon>
          </v-btn>
          <v-btn icon small
            @click.stop="manageDates(leaveStatus.REJECTED, item, leaves.items.indexOf(item))"
            v-if="canManage(item.status, item.user.id) && noActionTakenOnAnyDateByThisManager(item.dates)">
            <v-icon>mdi-thumb-down-outline</v-icon>
          </v-btn>
          <v-btn icon small
            @click.stop="cancelDate(item, leaves.items.indexOf(item))"
            v-if="canCancel(item.status, item.user.id)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn v-if="canUpdateOrDelete(item.canEdit)" icon small @click.stop="updateLeaveRequest(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn v-if="canUpdateOrDelete(item.canEdit)" icon small
            @click.stop="deleteLeaveRequest(item, leaves.items.indexOf(item))">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <tr v-for="(leaveDetails, j) in item.dates" :key="j" class="row custom-border-bottom">
              <v-col class="text-start align-middle-vertical" cols="7">
                {{ leaveDetails.dateWithTimezone }}
                <template v-if="item.startTime && item.endTime">
                  [{{ item.startTime }} - {{ item.endTime }}]
                </template>
              </v-col>
              <v-col class=" align-middle-vertical" cols="2">
                <v-badge avatar bordered bottom overlap color="background"
                  v-if="item.status === leaveStatus.APPROVED && !item.leave.requiresApproval">
                  <v-avatar size="35" color="primary">
                    <v-icon color="background">mdi-robot</v-icon>
                  </v-avatar>
                  <template v-slot:badge>
                    <v-avatar>
                      <v-icon x-small :color="statuses[leaveStatus.APPROVED].color">
                        {{ statuses[leaveStatus.APPROVED].icon }}
                      </v-icon>
                    </v-avatar>
                  </template>
                </v-badge>
                <v-badge avatar bordered bottom overlap :key="i" color="background" class="mx-2"
                  v-for="({manager, response}, i) in leaveDetails.managers" v-else>
                  <v-tooltip bottom  v-if="_.isNil(manager)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar size="35" color="primary" v-on="on" v-bind="attrs">
                        <v-icon color="background">mdi-account-cancel</v-icon>
                      </v-avatar>
                    </template>
                    <span>{{ $t('views.leaves.inactiveUser') }}</span>
                  </v-tooltip>
                  <v-avatar size="35" v-else>
                    <app-avatar :url="getFileUrl(manager.avatar)" :alternative="manager.initials"
                      size="xsmall" :tooltip="manager.name" />
                  </v-avatar>
                  <template v-slot:badge>
                    <v-avatar>
                      <v-icon x-small :color="statuses[response].color">{{ statuses[response].icon }}</v-icon>
                    </v-avatar>
                  </template>
                </v-badge>
              </v-col>
              <v-col class="text-center align-middle-vertical" cols="2">
                <v-btn icon small @click.stop="manageDates(leaveStatus.APPROVED , item, leaves.items.indexOf(item), j)"
                  v-show="canManage(leaveDetails.status, item.user.id)">
                  <v-icon>mdi-thumb-up-outline</v-icon>
                </v-btn>
                <v-btn icon small @click.stop="manageDates(leaveStatus.REJECTED,  item, leaves.items.indexOf(item), j)"
                  v-show="canManage(leaveDetails.status, item.user.id)">
                  <v-icon>mdi-thumb-down-outline</v-icon>
                </v-btn>
                <v-btn icon small @click.stop="cancelDate(item, leaves.items.indexOf(item), j)"
                  v-show="canCancel(leaveDetails.status, item.user.id)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1"></v-col>
            </tr>
            <tr v-if="item.note" class="row note">
              <v-col>
               <h4>{{ $t('models.leaveRequest.attributes.note') }}: </h4>
               <p class="text-caption">{{ item.note }}</p>
              </v-col>
            </tr>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';
import {
  leaveStatus, leaveUnits, leaveTables
} from 'constants';

export default {
  name: 'leavesTable',
  props: {
    leaves: {
      type: Object,
      required: true
    },
    tableName: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      moment,
      leaveStatus,
      leaveUnits,
      leaveTables,
      expanded: [],
      isModalVisible: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['requestedDates'],
        sortDesc: [true],
        mustSort: ['true'],
        multiSort: false,
        filterBy: {year: moment().year()}
      },
      statuses: {
        pending: {
          icon: 'mdi-timer-sand',
          color: 'info'
        },
        approved: {
          icon: 'mdi-thumb-up-outline',
          color: 'success'
        },
        rejected: {
          icon: 'mdi-thumb-down-outline',
          color: 'danger'
        },
        canceled: {
          icon: 'mdi-trash-can-outline',
          color: 'muted'
        },
        partially_approved: {
          icon: 'mdi-minus-circle-outline',
          color: 'warning'
        }
      },
      modal: {
        show: false,
        id: '',
        resetForm: false,
        title: this.$t('views.leaves.cancelReason'),
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          note: {
            type: 'textarea',
            key: 'note',
            value: '',
            rules: [{name: 'required'}],
            label: this.$t('models.leaveRequest.attributes.note'),
            classes: 'col-12 py-0'
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'isAdmin']),
    headers: function() {
      return [
        {
          text: this.$t('models.leaveRequest.attributes.user'),
          value: 'userId',
          show: this.tableName !== this.leaveTables.USER_HISTORY_REQUESTS
        },
        {
          text: this.$t('models.leaveRequest.attributes.leaveName'),
          value: 'leave.name',
          show: true
        },
        {
          text: this.$t('models.leaveRequest.attributes.dates'),
          value: 'requestedDates',
          show: true
        },
        {
          text: this.$t('models.leaveRequest.attributes.duration'),
          value: 'duration',
          show: true
        },
        {
          text: this.tableName === this.leaveTables.MANAGER_PENDING_REQUESTS ?
            '' : this.$t('models.leaveRequest.attributes.status'),
          value: 'status',
          show: true
        },
        {
          text: '',
          sortable: false,
          value: 'actions',
          show: true
        },
        {
          text: '',
          sortable: false,
          value: 'data-table-expand',
          show: true
        }
      ];
    },
    visibleHeaders() {
      return this.headers.filter((header) => header.show);
    },
    yearOptions() {
      let currentYear = this.moment().year();
      return Array(3).fill().map(() => currentYear--);
    }
  },
  methods: {
    canManage(status, userId) {
      return status === this.leaveStatus.PENDING && this.tableName === leaveTables.MANAGER_PENDING_REQUESTS &&
        userId !== this.currentUser.id;
    },
    noActionTakenOnAnyDateByThisManager(dates) {
      return (this.isAdmin && !this._.some(dates, {managers: [{id: this.currentUser.id}]})) ||
              this._.every(dates, {managers: [{id: this.currentUser.id, response: this.leaveStatus.PENDING}]});
    },
    canCancel(status, userId) {
      return (this.currentUser.theOnlyAdmin ||
        ((status === this.leaveStatus.APPROVED || status === this.leaveStatus.PARTIALLY_APPROVED) &&
        this.isAdmin && userId !== this.currentUser.id)) && this.tableName === leaveTables.MANAGER_HISTORY_REQUESTS;
    },
    canUpdateOrDelete(canEdit) {
      return canEdit && (this.tableName === leaveTables.USER_HISTORY_REQUESTS ||
        (this.tableName === leaveTables.MANAGER_PENDING_REQUESTS && this.isAdmin));
    },
    filterTable(year) {
      this.options.filterBy.year = year;
      this.updateOptions(this.options);
    },
    updateOptions(options) {
      options.tableName = this.tableName;
      this.$emit('updateOptions', options);
    },
    expandRow(value) {
      this.expanded.push(value);
    },
    manageDates(type, item, index, key = -1) {
      const data = {
        id: item.id,
        status: item.status,
        index: index
      };
      const dates = key !== -1 ?
        [item.dates[key].date] :
        item.dates.filter((date) => date.status === this.leaveStatus.PENDING).map((date) => date.date);
      if (type === leaveStatus.APPROVED) {
        data.form = {approved_dates: dates};
      } else if (type === leaveStatus.REJECTED) {
        data.form = {rejected_dates: dates};
      }
      this.$emit('manageLeave', {data: data});
    },
    cancelDate(item, index, key = -1) {
      const data = {
        id: item.id,
        status: item.status,
        index: index,
        form: {
          canceled_dates:
            key !== -1 ?
              [item.dates[key].date] :
              item.dates.filter((date) => date.status !== this.leaveStatus.CANCELED).map((date) => date.date)
        }
      };
      this.modal.fields.note.value = item.note;
      this.modal.show = true;
      this.modal.data = data;
    },
    submitCancelNote() {
      this.modal.data.form.note = this.modal.fields.note.value;
      this.$emit('manageLeave', {data: this.modal.data, modal: this.modal});
    },
    deleteLeaveRequest(leaveRequest, index) {
      this.$confirm(this.$t('messages.confirmation.delete', {entity: this.$tc('models.leaveRequest.entity', 1)}), {
        buttonTrueText: this.$t('actions.confirm'),
        buttonFalseText: this.$t('actions.cancel')
      }).then((confirmed) => {
        if (confirmed) {
          this.$emit('deleteLeaveRequest', {id: leaveRequest.id, index: index});
        }
      });
    },
    updateLeaveRequest(leaveRequest) {
      this.$router.push({
        name: 'EditLeaveRequest',
        params: {id: leaveRequest.id}
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-border-bottom {
  border-bottom: 1px solid #ececec;
}
.note {
  background-color: var(--v-background);
}
.align-middle-vertical {
  line-height: 35px;
}
</style>
